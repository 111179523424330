body {
  overflow-y: auto;
  font-family: Arial, Helvetica, sans-serif;
}

h1 {
  padding-top: 1em;
  color: #025570;
}

h3 {
  font-size: 1.5em;
  font-weight: bold;
  padding-bottom: 0.2em;
}

li {
  font-size: 1.2em;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  color: #2E323A;
}

p {
  font-size: 1.5em;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 500px;
}

.page {
  width: 100%;
  max-width: 1200px;
}

.navbar-bg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.navbar-icon {
  width: 5em;
  height: 5em;
  margin-bottom: 20px;
  border-radius: 50%;
}

@media (prefers-reduced-motion: no-preference) {
  .navbar-icon {
    animation: navbar-icon-spin infinite 20s linear;
  }
}

.navbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
}

.navbar-header {
  display: flex;
  width: 100%;
  height: 300px; 
  overflow: hidden;
  position: relative;
}

.navbar-button-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex
}

.navbar-button {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

.App-active-link {
  font-weight: bold;
}

.App-button {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 1.5em;
  font-weight: bold;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-Color: white; 
  margin: 10px;
  cursor: pointer;
  border: 2px solid #DE813D;
  color: #DE813D;
}

.navbar-title {
  color: #2E323A;
}

.link-button {
  margin: 20px;
  font-size: 1.5em;
  text-decoration: underline;
}

.rc-collapse {
  background-color: #fff !important;
  border-radius: 0 !important;
  border-width: 0 !important;
  border-bottom: 1px solid #d9d9d9 !important;
}
.rc-collapse > .rc-collapse-item > .rc-collapse-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  line-height: 22px;
  padding: 0px 0px !important;
  color: #000000 !important;
  font-weight: bold;
  cursor: pointer;
  font-size: 20px;
}
.rc-collapse > .rc-collapse-item > .rc-collapse-header .rc-collapse-extra {
  margin: 0 !important;
  width: 50%;
  display: flex;
  justify-content: flex-end;
}
.rc-collapse-content {
  overflow: hidden;
  color: #000000 !important;
  padding: 0 16px;
  background-color: #fff;
}
.rc-collapse-content > .rc-collapse-content-box {
  margin-top: 0px !important;
  margin-bottom: 16px;
}